<template>
  <!-- Error page-->
  <b-card>
    <div class="misc-wrapper">
      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">
            Wah nyasar nih! 🕵🏻‍♀️
          </h2>
          <p class="mb-2">
            Oops! Halaman yang kamu cari tidak ditemukan.
          </p>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-sm-block"
            @click="$router.go(-1)"
          >
            Kembali ke halaman sebelumnya
          </b-button>

          <!-- image -->
          <Lottie
            class="unauth-lottie"
            :options="{
              animationData: notfound,
            }"
          />
        </div>
      </div>
    </div>
  </b-card>
<!-- / Error page-->
</template>

<script>
import { BButton, BCard } from 'bootstrap-vue'
import Lottie from 'vue-lottie'
import notfound from '@/assets/images/lottie/notfound.json'

export default {
  components: {
    BButton,
    BCard,
    Lottie,
  },
  data() {
    return {
      notfound,
    }
  },
}
</script>

<style>
  .misc-wrapper {
    min-height: 60vh !important;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
